<template>
  <div>
    <div class="complex-rate-list-block">
      <h3 class="block-header block-header-main">{{title}} </h3>
      <div v-for="item in data" :key="getkey(item)">
        <ComplexAdditionalServiceBlock v-if="parseInt(block_type) == 1" :data="item"/>
        <ComplexStorageServiceBlock v-else :data="item"/>
      </div>
    </div>
  </div>
</template>

<script>
  import md5 from "md5";
  import ComplexStorageServiceBlock from "./ComplexStorageServiceBlock.vue";
  import ComplexAdditionalServiceBlock from "./ComplexAdditionalServiceBlock.vue"
  export default {
    name: 'ComplexAdditionalService',
        props:{
            data: {
                type: [Array, Object]
            },
            title: {
                type: String
            },
            block_type:{
                type: String
            }
        },
        components:{
            ComplexAdditionalServiceBlock,
            ComplexStorageServiceBlock,
        },
        data: () => ({

        }),
    methods: {
        getkey(item){
            return md5(item.group_type + item.line_id + item.place_to_id + item.point_to_id)
        },
    },
  }
</script>

<style scoped>
.complex-rate-list-block{
  margin-left: 1.61%;
  margin-right: 1.61%;
  margin-top: 0.85%;
  margin-bottom: 1.61%;
  border: 1px solid #e6eae9;
}
.complex-ship-schedule{
    background-color: #fff;
    margin-bottom: 0.4%;
    width: 95%;
    margin: auto;
    padding-bottom: 10%;
}
.block-header-main{
    width: 27%;
}
.block-header-auto{
    width: 100%;
}
.block-header-sub{
    width: 47%;
}
.block-header{
  margin-left: 1.61%;
  border-bottom: 1px solid #e6eae9;
}
</style>
