<template>
    <div>
        <h4 class="block-header block-header-sub">{{(data.line_name != null) ? "Линия " + data.line_name + ", " : ""}}{{data.place_to_name + '(' + data.point_to_name + ')'}}</h4>
        <table class="rate-card-detail-table" v-for="arr in data.data" :key="arr">
            <tr v-for="item in arr.services" :key="item.service_id">
                <td class="rate-card-detail-row-station-caption"><h4>{{ item.comment }}</h4></td>
                <td class="rate-card-detail-row-bottom-border">{{ item.obj_name }}</td>
                <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price_20) }}&nbsp;
                    <span v-if="item.currency_id == '840'">&#36;</span>
                    <span v-else>&#8381;</span></td>
                <td class="td-right rate-card-detail-row-bottom-border">{{ getnumber(item.price_40) }}&nbsp;
                    <span v-if="item.currency_id == '840'">&#36;</span>
                    <span v-else>&#8381;</span></td>
            </tr>
        </table>
    </div>
</template>

<script>
  import md5 from "md5";
  export default {
    name: 'ComplexAdditionalServiceBlock',
        props:{
            data: {
                type: [Array, Object]
            },
        },
        components:{

        },
    data: () => ({

    }),
    methods: {
        getkey(item){
            return md5(item.join(''))
        },
        getnumber(value) {
            return parseFloat(value).toLocaleString("ru-RU");
        },
    },
  }
</script>

<style scoped>
.rate-card-detail-table {
  width: 95%;
  font-size: 10pt;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin: auto;
  border-collapse: collapse;
}
.rate-card-detail-table-head{
    text-align: center;
    font-weight: bold;
}
.td-right{
    text-align: right;
    border-right: 1px solid #e6eae9;
    padding-right: 0.425%;
}
.td-left{
    text-align: left;
}
.td-center{
    text-align: center;
}
.rate-card-detail-table tr{
    line-height: 25px;
}
.rate-card-detail-table td{
    border-bottom: 1px solid #e6eae9;
}
tr:nth-child(even) {
    background: #e6eae9;
}
.td-width-30{
    width: 30%;
}
.td-width-10{
    width: 10%;
}
.block-header-main{
    width: 27%;
}
.block-header-auto{
    width: 100%;
}
.block-header-sub{
    width: 47%;
}
.block-header{
  margin-left: 1.61%;
  border-bottom: 1px solid #e6eae9;
}
.rate-card-detail-row-station-caption{
    width: 45%
}
.rate-card-detail-row-bottom-border{
  border-bottom: 1px solid #e6eae9;
}
</style>
