<script>
import AppToggle from "@/components/ui/AppToggle";
import AppButton from "@/components/ui/AppButton";

export default {
  name: "RateComplexParameters",

  components: {
    AppToggle,
    AppButton,
  },

  props: {
    agent_cost: Number,
    is_show_20: Boolean,
    is_show_24: Boolean,
    is_show_40: Boolean,
    is_show_agent: Boolean,
    is_show_auto: Boolean,
  },
    };
</script>

<template>
  <div class="complexrate-parameters">
    <p class="complexrate-parameters__title">Параметры отображения:</p>
    <div class="complexrate-parameters__items">
      <div class="complexrate-parameters__items-toggle">
        <AppToggle
          @change="$emit('update:is_show_auto', is_show_auto)"
          v-if="is_show_auto"
          label="Показать/скрыть блок автовывоза"
          v-model="is_show_auto"
        />

        <AppToggle
          @change="$emit('update:is_show_20', is_show_20)"
          label="Тарифы 20`DV"
          v-model="is_show_20"
        />

        <AppToggle
          @change="$emit('update:is_show_24', is_show_24)"
          label="Тарифы 20`DV Heavy"
          v-model="is_show_24"
        />

        <AppToggle
          @change="$emit('update:is_show_40', is_show_40)"
          label="Тарифы 40`HC"
          v-model="is_show_40"
        />

        <div class="rate-complex-advansed-parameters-element">
          <v-text-field
            class="rate-complex-advansed-parameters-element__text"
            v-if="is_show_agent"
            v-model="agent_cost"
            label="Тариф агента"
            color="var(--main-bg-color)"
            @input="$emit('update:agent_cost', agent_cost)"
          >
          </v-text-field>
        </div>
      </div>

      <div class="complexrate-parameters__down-btn">
        <AppButton
          @click="$emit('exportFile')"
          label="Скачать"
          :rightIcon="require('@/assets/img/icons/export-file-icon.svg')"
          color="secondary-blue"
        />
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.complexrate-parameters {
  @media (max-width: $tablet-width) {
    padding: 0px 16px;
  }
  &__title {
    @include font-headline-2;
    margin-bottom: 8px;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tablet-width){
        gap: 10px 0;
    }

    @media (max-width: $mobile-width) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 400px) {
      gap: 0;
    }
  }

  &__items-toggle {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__down-btn {
    @media (max-width: 400px) {
      width: 100%;
    }
  }
}
</style>