<template>
  <div class="rate-not-found-page">
    <div class="rate-not-found-img">
      <v-img src="not-found-icon-24.jpg"></v-img>
    </div>
    <div class="rate-not-found-text">
      По данному запросу ставки не найдены.
    </div>
    <v-alert
      text
      class="rate-not-found-text-alternative"
      icon=" "
      color="var(--main-bg-color)"
      type="success"
    >
      <div class="rate-not-found-text-alternative">
        {{currentList == 'complex' 
        ? 'Попробуйте изменить параметры поиска' 
        : 'Рекомендуем ознакомиться с альтернативными маршрутами'}}
      </div>
    </v-alert>
  </div>
</template>

<script>
import {bus} from '@/router/bus';

export default {
  name: "RateCardNotFound.vue",
  props: {
    parameters: {
      type: [Object, Array]
    },

    currentList:{
      type: String
    }

  },
  data: () => ({
    is_show_request_button: false,
  }),
  methods:{
    createRateRequest(){
      bus.$emit("show_popup", );
    }
  }
}
</script>

<style scoped lang="scss">
.rate-not-found-page{
  margin: auto;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.rate-not-found-img{
  width: 15%;
  margin: auto;
  padding-top: 1.61%;
  padding-bottom: 3.2%;
}
.rate-not-found-text{
  /*padding-top: 20px;*/
  padding-bottom: 10px;
}

.rate-not-found-text-alternative {
  font-size: 18px;
  font-weight: 600;
  
  @media (max-width: 600px) {
    display: none;
  }
}
</style>
