<script>
export default {
  name: "AppToggle",

  props: {
    label: { type: String, required: true },
    value: { type: Boolean, required: true },
  },

  computed: {
    selectedValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
        this.onChange();
      },
    },
  },

  methods: {
    onChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>

<template>
  <div class="toggle">
    <p class="toggle__text">{{ label }}</p>
    <label
      @click="onChange"
      class="toggle__wrap"
    >
      <input type="checkbox" v-model="selectedValue" />
      <span class="toggle__mark" :class="{ active: selectedValue }"></span>
    </label>
  </div>
</template>


<style scoped lang="scss">
.toggle {
  display: flex;
  align-items: center;

  &__wrap {
    width: 32px;
    height: 16px;
    background: var(--grey-50);
    border-radius: 28px;
    position: relative;
    transition: 0.2s;

    input {
      display: none;
    }

    &:has(.active) {
      background: var(--blue-70);
    }
  }

  &__mark {
    display: block;
    border-radius: 100%;
    background-color: #fff;
    width: 12px;
    height: 12px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;

    transition: 0.2s;
  }

  input {
    display: none;

    &:checked + span {
      left: 18px;
    }
  }

  &__text {
    @include font-body-3;
    margin-right: 8px;
  }
}
</style>