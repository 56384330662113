<template>
  <div class="rate-variants">
    <div
      class="rate-variants__loader-block"
       v-if="showLoader === true"
    >
      <v-progress-circular
        :size="100"
        :width="10"
        color="var(--main-orange)"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <div
      v-for="item in requestRates"
      :key="item.key"
    >
      <RateCardShort :rate="item"/>
    </div>
  </div>
</template>

<script>
import RateCardShort from "../rate-card/RateCardShort.vue";
import { bus } from "../../router/bus";
export default {
  name: "ComplexRateVariants.vue",
  props: {
    variants: {
      type: Array,
    },
    
    cid: {
      type: String,
    },
    
    on_date: {
      type: Date,
    },
  },
  
  components: {
    RateCardShort,
  },
  
  data: () => ({
    showLoader: true,
    requestRates: [],
  }),
  
  mounted() {
    this.requestRates = []
    for (let i = 0; i < this.variants.length; i++) {
      this.getRateById(this.variants[i].key)
    }
  },
  
  methods: {
    getRateById(key) {
      this.$postapi(this.$address + this.$getters.getRateByKey.uri, {
        method: this.$getters.getRateByKey.name,
        key: key,
        on_date: this.on_date,
        client_id: this.cid
      }).then((data) => {
        if (data.error == 0) {
          let response = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          )[0]
          this.requestRates.push(response)
          this.showLoader = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },
  }
  
}
</script>

<style scoped lang="scss">
.rate-variants {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2% 0;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
  
  &__loader-block {
    margin: 10px auto;
  }
  
  &__rate-card {
  }
}
</style>
