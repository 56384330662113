<template>
  <div>
    <h4 class="block-header block-header-sub">Линия {{shedule.transporter_name}},&nbsp;{{shedule.place_from_name}} - {{shedule.place_to_name + '(' + shedule.point_to_name + ')'}}</h4>
    <table class="rate-card-detail-table">
      <thead class="rate-card-detail-table-head">
      <th class="td-left td-width-30">Наименование судна</th>
      <th class="td-center td-width-10">Дата выхода</th>
      <th class="td-center td-width-10">Дата прибытия</th>
      <th class="td-left">Порт отправки</th>
      <th class="td-left">Порт прибытия</th>
      <th class="td-center">Прямой роутинг</th>
      </thead>
      <tr v-for="item in shedule.data" :key="item.id">
        <td class="td-left">{{item.vassel_name}}</td>
        <td class="td-center">{{getdate(item.sea_start_date)}}</td>
        <td class="td-center">{{getdate(item.sea_end_date)}}</td>
        <td class="td-left">{{shedule.place_from_name}}</td>
        <td class="td-left">{{shedule.place_to_name + '(' + shedule.point_to_name + ')'}}</td>
        <td class="td-center">{{(item.is_not_direct_route == false ? 'Да' : 'Нет')}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ComplexShipScheduleBlock',
  props:{
    shedule: {
      type: [Array, Object]
    }
  },
  components:{
  
  },
  data: () => ({
  
  }),
  methods: {
    getdate(value) {
      if (value != null)
        return (new Date(value)).toLocaleString("ru", {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timezone: 'UTC'
        });
      else
        return ' '
    },
  },
}
</script>

<style scoped>
.rate-card-detail-table {
  width: 95%;
  font-size: 10pt;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin: auto;
  border-collapse: collapse;
}
.rate-card-detail-table-head{
  text-align: center;
  font-weight: bold;
}
.td-right{
  text-align: right;
  border-right: 1px solid #e6eae9;
  padding-right: 0.425%;
}
.td-left{
  text-align: left;
}
.td-center{
  text-align: center;
}
.rate-card-detail-table tr{
  line-height: 25px;
}
.rate-card-detail-table td{
  border-bottom: 1px solid #e6eae9;
}
tr:nth-child(even) {
  background: #e6eae9;
}
.td-width-30{
  width: 30%;
}
.td-width-10{
  width: 10%;
}
.block-header-main{
  width: 27%;
}
.block-header-auto{
  width: 100%;
}
.block-header-sub{
  width: 47%;
}
.block-header{
  margin-left: 1.61%;
  border-bottom: 1px solid #e6eae9;
}
</style>
