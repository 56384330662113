<template>
  <div class="complex-rate-list-block">
    <h3 class="block-header block-header-main">{{title}}</h3>
    <div v-for="item in shedules" :key="getkey(item)">
      <ComplexShipScheduleBlock :shedule="item"/>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import ComplexShipScheduleBlock from "./ComplexShipScheduleBlock.vue"
export default {
  name: 'ComplexShipSchedule',
  props:{
    shedules: {
      type: [Array, Object]
    },
    title: {
      type: String
    }
  },
  components:{
    ComplexShipScheduleBlock
  },
  data: () => ({
  
  }),
  methods: {
    getkey(item){
      return md5(item.place_from_id + item.place_to_id + item.point_to_id + item.transporter_id)
    },
  },
}
</script>

<style scoped>
.complex-rate-list-block{
  margin-left: 1.61%;
  margin-right: 1.61%;
  margin-top: 0.85%;
  margin-bottom: 1.61%;
  border: 1px solid #e6eae9;
}
.complex-ship-schedule{
  background-color: #fff;
  margin-bottom: 0.4%;
  width: 95%;
  margin: auto;
  padding-bottom: 10%;
}
.block-header-main{
  width: 27%;
}
.block-header-auto{
  width: 100%;
}
.block-header-sub{
  width: 47%;
}
.block-header{
  margin-left: 1.61%;
  border-bottom: 1px solid #e6eae9;
}
</style>
